import { Injectable } from '@angular/core';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
// Is only used to revert the AbpTitleStrategy to the normal TitleStrategy
export class CustomTitleStrategy extends TitleStrategy {
  override updateTitle(snapshot: RouterStateSnapshot): void {
    return;
  }
}
