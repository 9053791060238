// src/app/services/auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {}

  getCurrentTenant() {
    return JSON.parse(localStorage.getItem('abpSession'))["tenant"]["id"];
  }
}
