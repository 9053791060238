<ng-container *lpxResponsive="'all md-none'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-container *lpxResponsive="'md'">
  <div class="lpx-scroll-container ps" *ngIf="usePerfectScrollbar" lpxPerfectScrollbar>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
  <div class="lpx-scroll-container ps" *ngIf="!usePerfectScrollbar">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>
<ng-template #content>
  <div
    id="lpx-wrapper"
    *ngIf="layoutService.containerClass$ | async as containerClass"
    [ngClass]="containerClass"
  >
    <div class="lpx-sidebar-container" *lpxResponsive="'md'">
      <div class="lpx-sidebar ps" *ngIf="usePerfectScrollbar" lpxPerfectScrollbar>
        <pm-navbar></pm-navbar>
      </div>
      <div class="lpx-sidebar ps" *ngIf="!usePerfectScrollbar">
        <pm-navbar></pm-navbar>
      </div>
    </div>

    <div class="lpx-content-container">
      <div class="lpx-topbar-container">
        <div class="lpx-topbar">
          <div class="lpx-topbar-content">
            <lpx-topbar-content></lpx-topbar-content>
          </div>
        </div>
      </div>
      <div class="lpx-content-wrapper">
        <div class="lpx-content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="lpx-footbar-container">
        <lpx-footer></lpx-footer>
      </div>
    </div>

    <app-pm-mobile-navbar *lpxResponsive="'all md-none'"></app-pm-mobile-navbar>

    <div class="lpx-toolbar-container" *lpxResponsive="'md'">
      <lpx-toolbar-container></lpx-toolbar-container>
      <lpx-avatar></lpx-avatar>
      <app-pm-toolbar-container></app-pm-toolbar-container>
    </div>

  </div>
</ng-template>
