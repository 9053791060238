<lpx-toolbar [profileRef]="profileRef$" (profileClick)="toggleCtxMenu()">
  <ng-container
    *ngIf="{
      user: userProfileService.user$ | async,
      profileRef: profileRef$ | async
    } as data"
  >
    <lpx-context-menu
      *ngIf="data.profileRef"
      #menu="lpx-context-menu"
      (lpxClickOutside)="menu.close()"
      [exceptedRefs]="[data.profileRef]"
    >
      <lpx-context-menu-header>
        <div class="lpx-user-ctx-header">
          <div class="lpx-user-ctx-img">
            <lpx-avatar [avatar]="data.user?.avatar"></lpx-avatar>
          </div>
          <div class="lpx-user-ctx-info">
            <span class="lpx-context-menu-user-name">{{
                data.user?.userName
              }}</span>
            <span
              *ngIf="data.user?.tenant?.name as tenantName"
              class="lpx-context-menu-user-tenant"
            >
              {{ tenantName }}
            </span>
            <span *ngIf="data.user?.tenant?.id"
                  class="mt-1 lpx-context-menu-user-tenant"
                  style="display:flex;align-items:center;gap:12px;">
              Anfragen: {{ !isLoadingQuestionStats ? statText : "" }}
              <i *ngIf="isLoadingQuestionStats"
                 class="pi pi-spin pi-spinner"
                 style="font-size: 18px;animation-duration: 0.75s;">
              </i>
            </span>
          </div>
        </div>
      </lpx-context-menu-header>

      <ng-container *ngFor="let actions of data.user?.userActionGroups;">
        <lpx-context-menu-action-group>
          <lpx-navbar-routes
            [navbarItems]="actions"
            [routerItem]="false"
          ></lpx-navbar-routes>
        </lpx-context-menu-action-group>
      </ng-container>

    </lpx-context-menu>
  </ng-container>
</lpx-toolbar>
