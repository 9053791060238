import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/dashboard',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-chart-line',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'GovernmentGpt.Dashboard.Host',
        invisible: false,
      },
      {
        path: '/free-inquiry',
        name: '::Menu:FreeInquiry',
        iconClass: 'pi pi-search',
        order: 2,
        layout: eLayoutType.application,
      },
      {
        path: '/assisted-inquiry',
        name: '::Menu:AssistedInquiry',
        iconClass: 'fas fa-headset',
        order: 3,
        layout: eLayoutType.application,
      },
    ]);
  };
}
