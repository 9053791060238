import { Component } from '@angular/core';
import { LpxSideMenuLayoutModule } from '@volosoft/ngx-lepton-x/layouts';
import { LpxResponsiveModule, PerfectScrollbarDirective } from '@volo/ngx-lepton-x.core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { PmNavbarComponent } from '../pm-navbar/pm-navbar.component';
import { LpxMobileNavbarModule } from '../pm-mobile-navbar';
import { SideMenuLayoutService } from './side-menu-layout.service';
import { LpxToolbarContainerModule } from '../pm-toolbar-container';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pm-side-menu-layout',
  templateUrl: './pm-side-menu-layout.component.html',
  standalone: true,
  imports: [
    LpxSideMenuLayoutModule,
    LpxResponsiveModule,
    RouterOutlet,
    NgTemplateOutlet,
    PmNavbarComponent,
    LpxMobileNavbarModule,
    AsyncPipe,
    NgIf,
    NgClass,
    LpxToolbarContainerModule,
    PerfectScrollbarDirective
  ],
  styleUrls: ['pm-side-menu-layout.component.scss']
})
export class PmSideMenuLayoutComponent {

  constructor(public layoutService: SideMenuLayoutService, private router: Router) { }

  get usePerfectScrollbar(): boolean {
    const path = this.router.url;
    return !(path.includes('/assisted-inquiry') || path.includes('/free-inquiry'));
  }
}
