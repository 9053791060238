import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { EnaioSessionService } from '../services/enaio-session.service';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate {

  constructor(
    private oAuthService: OAuthService,
    private enaioSessionService: EnaioSessionService,
  ) {}

  async canActivate(): Promise<boolean> {
    const isSessionValid = await this.enaioSessionService.refreshSession();
    if (isSessionValid) {
      return true;
    }
    this.oAuthService.revokeTokenAndLogout()
      .then(() => console.debug('Session invalid. Logging out user.'))
      .catch(err => console.debug('Error while logging out user: ' + err));
    return false;
  }
}
