import {
  SetTenantPasswordModalInputs,
  SetTenantPasswordModalOutputs,
} from '@volo/abp.ng.saas/lib/models/set-tenant-password-modal.model';
import { ABP, ListService, PagedResultDto, ReplaceableComponents } from '@abp/ng.core';
import {
  Confirmation,
  ConfirmationService,
  ToasterService,
  DateAdapter,
} from '@abp/ng.theme.shared';
import {
  EXTENSIONS_IDENTIFIER,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.components/extensible';
import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  EditionDto,
  EditionLookupDto,
  EditionService,
  GetTenantsInput,
  SaasTenantCreateDto,
  SaasTenantDto, TenantActivationState,
  tenantActivationStateOptions,
  TenantService
} from '@volo/abp.ng.saas/proxy';
import { finalize, map, tap } from 'rxjs/operators';
import { eSaasComponents } from '@volo/abp.ng.saas/lib/enums/components';
import { ConnectionStringsComponent, TenantsComponent } from '@volo/abp.ng.saas';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CustomTenantService } from '../../services/custom-tenant.service';
import { HttpErrorResponse } from "@angular/common/http";

interface SelectedModalContent {
  type: 'saveConnStr' | 'saveTenant';
  title: string;
  template: TemplateRef<any>;
}

@Component({
  selector: 'abp-tenant',
  templateUrl: './custom-tenant.component.html',
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eSaasComponents.Tenants,
    },
    {
      provide: TenantsComponent,
      useExisting: CustomTenantComponent
    },
    { provide: NgbDateAdapter, useClass: DateAdapter },
  ],
})
export class CustomTenantComponent implements OnInit {
  @ViewChild('tenantModalTemplate') tenantModalTemplate: any;
  @ViewChild('connectionStringModalTemplate') connectionStringModalTemplate: any;
  @ViewChild(ConnectionStringsComponent) connectionStringsRef: ConnectionStringsComponent;

  data: PagedResultDto<SaasTenantDto> = { items: [], totalCount: 0 };
  editions: EditionDto[] = [];

  statText: string= "";

  selected: SaasTenantDto = {} as SaasTenantDto;

  selectedUserImageFile: File | null = null;
  userImageFileError: string = '';

  selectedAssistantImageFile: File | null = null;
  assistantImageFileError: string = '';

  tenantForm: UntypedFormGroup;

  isModalVisible: boolean = false;

  selectedModalContent: SelectedModalContent = {} as SelectedModalContent;

  visibleFeatures: boolean = false;
  isVisibleSetTenantPasswordModal: boolean = false;
  selectedTenantId: string = '';
  selectedTenantNameForSetTenantPasswordModal: string = '';
  providerKey: string;

  modalBusy: boolean = false;

  emptyOption: {label: string, value: any} = { label: '-', value: null };

  connectionStringsReplacementKey: eSaasComponents = eSaasComponents.ConnectionStrings;
  setTenantPasswordReplacementKey: eSaasComponents = eSaasComponents.SetTenantPassword;
  formFilters: UntypedFormGroup;
  activationStateOptions:ABP.Option<typeof TenantActivationState>[] = tenantActivationStateOptions;

  tenantTestLoginResult: any;
  setTenantReplaceableTemplateOptions: ReplaceableComponents.ReplaceableTemplateDirectiveInput<
    SetTenantPasswordModalInputs,
    SetTenantPasswordModalOutputs
  > | null = null;

  constructor(
    public readonly list: ListService<GetTenantsInput>,
    protected confirmationService: ConfirmationService,
    protected tenantService: TenantService,
    protected customTenantService: CustomTenantService,
    protected fb: UntypedFormBuilder,
    protected injector: Injector,
    protected toaster: ToasterService,
    public readonly editionService: EditionService,
  ) {}

  ngOnInit() {
    this.buildForm();
    this.hookToQuery();
  }

  get hasSelectedTenant(): boolean {
    return Boolean(this.selected.id);
  }

  get isDisabledSaveButton(): boolean {
    if (!this.selectedModalContent) {
      return false;
    }

    const { type } = this.selectedModalContent;
    const invalid = this.connectionStringsRef?.form.invalid;

    if (type === 'saveConnStr' && invalid) {
      return true;
    }

    return type === 'saveTenant' && this.tenantForm && this.tenantForm.invalid;
  }

  onVisibleFeaturesChange = (value: boolean) => {
    this.visibleFeatures = value;
  };

  clearFilters() {
    this.formFilters.reset();
  }

  ///////// start modal logic//////

  closeSetTenantPasswordModal = () => {
    this.isVisibleSetTenantPasswordModal = false;
    this.selectedTenantNameForSetTenantPasswordModal = '';
    this.selectedTenantId = '';
    this.setTenantReplaceableTemplateOptions = null;
  };

  openModal(title: string, template: TemplateRef<any>, type: 'saveConnStr' | 'saveTenant') {
    this.selectedModalContent = {
      title,
      template,
      type,
    };

    this.isModalVisible = true;
    this.userImageFileError = null;
  }

  openFeaturesModal(providerKey: string) {
    this.providerKey = providerKey;
    setTimeout(() => {
      this.visibleFeatures = true;
    }, 0);
  }

  openSetTenantPasswordModal(id: string, name: string) {
    this.isVisibleSetTenantPasswordModal = true;
    this.selectedTenantNameForSetTenantPasswordModal = name;
    this.selectedTenantId = id;

    this.setTenantReplaceableTemplateOptions = {
      outputs: { modalVisibleChange: this.closeSetTenantPasswordModal },
      inputs: {
        modalVisible: { value: this.isVisibleSetTenantPasswordModal },
        tenantId: { value: this.selectedTenantId },
        tenantName: { value: this.selectedTenantNameForSetTenantPasswordModal },
      },
      componentKey: this.setTenantPasswordReplacementKey,
    };
  }

  ///////// end modal logic//////

  onEditConnectionString(tenant: SaasTenantDto) {
    this.selected = tenant;

    this.openModal('Saas::ConnectionStrings', this.connectionStringModalTemplate, 'saveConnStr');
  }

  onAddTenant() {
    this.selected = {} as SaasTenantDto;
    this.createTenantForm().subscribe(() =>
      this.openModal('Saas::NewTenant', this.tenantModalTemplate, 'saveTenant'),
    );
  }

  onEditTenant(id: string) {
    this.tenantTestLoginResult = "";
    this.tenantService.get(id).subscribe(tenant => {
      this.selected = tenant;
      this.customTenantService.getTenantQuestionCount(id).subscribe(stats => {
        this.statText = (stats["totalQuestions"] ?? "0") + " / " + (stats["maxQuestions"] ?? "0")
      })
      this.createTenantForm().subscribe(() =>
        this.openModal('Saas::Edit', this.tenantModalTemplate, 'saveTenant'),
      );
    });
  }

  testTenantLogin() {
    this.customTenantService.testTenantLogin(this.selected.id).subscribe({
      next: (res: any) => {
        this.tenantTestLoginResult = res;
      },
      error: (err: HttpErrorResponse) => {
        this.tenantTestLoginResult = err ;
      }
    });
  }

  testTenant2FALogin() {
    this.customTenantService.testTenant2FALogin(this.selected.id).subscribe({
      next: (res: any) => {
        this.tenantTestLoginResult = res;
      },
      error: (err: HttpErrorResponse) => {
        this.tenantTestLoginResult = err ;
      }
    });
  }

  save() {
    const { type } = this.selectedModalContent || {};

    if (type === 'saveTenant') {
      this.saveTenant();
      return;
    }

    if (type === 'saveConnStr') {
      this.saveConnectionString();
    }
  }

  saveConnectionString() {
    if (this.modalBusy) return;
    if (this.connectionStringsRef.form.invalid) {
      return;
    }

    this.tenantService
      .updateConnectionStrings(this.selected.id, this.connectionStringsRef.getInput())
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isModalVisible = false;
        this.list.get();
      });
  }

  saveTenant() {
    if (!this.tenantForm.valid || this.modalBusy) return;
    this.modalBusy = true;

    const { activationEndDate } = this.tenantForm.value;

    const value = {
      ...this.tenantForm.value,
      ...(activationEndDate && {
        activationEndDate: new Date(activationEndDate).toLocalISOString(),
      }),
      ...(!this.selected.id && { connectionStrings: this.connectionStringsRef.getInput() }),
    } as SaasTenantCreateDto;

    const { id } = this.selected;
    (id ? this.tenantService.update(id, { ...this.selected, ...value }) : this.tenantService.create(value))
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(savedTenant => {
        if (this.selectedUserImageFile && savedTenant.id) {
          this.uploadUserImageFile(savedTenant.id);
        }
        if (this.selectedAssistantImageFile && savedTenant.id) {
          this.uploadAssistantImageFile(savedTenant.id);
        }

        this.list.get();
        this.isModalVisible = false;
      });
  }

  delete(id: string, name: string) {
    this.confirmationService
      .warn('Saas::TenantDeletionConfirmationMessage', 'Saas::AreYouSure', {
        messageLocalizationParams: [name],
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.tenantService.delete(id).subscribe(() => this.list.get());
        }
      });
  }

  onSharedDatabaseChange(value: boolean) {
    if (!value) {
      setTimeout(() => {
        const defaultConnectionString = document.getElementById(
          'defaultConnectionString',
        ) as HTMLInputElement;
        if (defaultConnectionString) {
          defaultConnectionString.focus();
        }
      }, 0);
    }
  }

  applyDatabaseMigrations(recordId: string) {
    this.tenantService.applyDatabaseMigrations(recordId).subscribe(() =>
      this.toaster.info('Saas::DatabaseMigrationQueuedAndWillBeApplied', '', {
        life: 6000,
      }),
    );
  }

  getData() {
    if (this.formFilters.invalid && this.formFilters.touched) {
      return;
    }
    this.list.get();
  }

  getEditionLookup() {
    return () =>
      this.tenantService
        .getEditionLookup()
        .pipe(map(items => ({ items } as PagedResultDto<EditionLookupDto>)));
  }

  onUserImageFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      if (file.type !== 'image/png') {
        this.userImageFileError = 'Only PNG files are allowed.';
        this.selectedUserImageFile = null;
        input.value = '';
      } else if (file.size > 2 * 1024 * 1024) {
        this.userImageFileError = 'File size should not exceed 2MB.';
        this.selectedUserImageFile = null;
        input.value = '';
      } else {
        this.userImageFileError = null;
        this.selectedUserImageFile = file;
      }
    }
  }

  onAssistantImageFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      if (file.type !== 'image/png') {
        this.assistantImageFileError = 'Only PNG files are allowed.';
        this.selectedAssistantImageFile = null;
        input.value = '';
      } else if (file.size > 2 * 1024 * 1024) {
        this.assistantImageFileError = 'File size should not exceed 2MB.';
        this.selectedAssistantImageFile = null;
        input.value = '';
      } else {
        this.assistantImageFileError = null;
        this.selectedAssistantImageFile = file;
      }
    }
  }

  uploadUserImageFile(id: string) {
    this.customTenantService.addOrUpdateUserImage(id, {"image": this.selectedUserImageFile});
  }

  uploadAssistantImageFile(id: string) {
    this.customTenantService.addOrUpdateAssistantImage(id, {"image": this.selectedAssistantImageFile});
  }

  protected hookToQuery() {
    this.list
      .hookToQuery(query => {
        const value = {
          ...this.formFilters.value,
          ...this.formFilters.value.times,
        };
        return this.tenantService.getList({ ...query, ...value });
      })
      .subscribe(res => (this.data = res));
  }

  protected createTenantForm() {
    return this.editionService.getList({ maxResultCount: 1000 }).pipe(
      tap(res => {
        this.editions = res.items;
        const data = new FormPropData(this.injector, this.selected);
        this.tenantForm = generateFormFromProps(data);
        if (!data.record?.editionId) {
          this.tenantForm.controls.editionId.patchValue(null);
        }
      }),
    );
  }

  private buildForm() {
    this.formFilters = this.fb.group({
      getEditionNames: [null, []],
      editionId: [null, []],
      times: [{}],
      activationState: [null, []],
    });
  }
}
