import { Injectable } from '@angular/core';
import { of, lastValueFrom } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root'
})
export class EnaioSessionService {

  constructor(private restService: RestService) { }

  refreshSession(): Promise<boolean> {
    return lastValueFrom(
      this.restService.request<any, boolean>(
        {
          method: 'GET',
          url: `/api/enaio/session/refresh`,
          responseType: 'json'
        }
      ).pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          console.error('Error while refreshing the session:', error);
          return of(false);
        })
      )
    )
  }
}
