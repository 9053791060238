import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const LANGUAGE: string = 'de-DE';

if (environment.production) {
  enableProdMode();
}

interceptLanguage();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function interceptLanguage(): void {
  const sessionStr = localStorage.getItem('abpSession');
  if (sessionStr) {
    const session = JSON.parse(sessionStr);
    session.language = LANGUAGE;
    const updatedSession = JSON.stringify(session);
    localStorage.setItem('abpSession', updatedSession);
  } else {
    const session = {"language": LANGUAGE};
    const updatedSession = JSON.stringify(session);
    localStorage.setItem('abpSession', updatedSession);
  }
}
