import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CustomTenantService } from './custom-tenant.service';

@Injectable({
  providedIn: 'root',
})
export class TenantCustomPropertiesService {
  readonly DEFAULT_USER_IMAGE_PATH: string = "/assets/images/default_user_image.png";
  readonly DEFAULT_ASSISTANT_IMAGE_PATH: string = "/assets/images/default_assistant_image.png";

  private userImageUrl: SafeUrl = this.DEFAULT_USER_IMAGE_PATH;
  private assistantImageUrl: SafeUrl = this.DEFAULT_ASSISTANT_IMAGE_PATH;
  private webClientBaseUrl: string = '';

  constructor(private tenantService: CustomTenantService, private sanitizer: DomSanitizer) {}

  initProperties(tenantId: string) {
    this.tenantService.getUserImage(tenantId).subscribe(image => {
      if (image && image.size > 0) {
        const objectURL = URL.createObjectURL(image);
        this.userImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
    });
    this.tenantService.getAssistantImage(tenantId).subscribe(image => {
      if (image && image.size > 0) {
        const objectURL = URL.createObjectURL(image);
        this.assistantImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
    });
    this.tenantService.getWebClientBaseUrl().subscribe(webClientBaseUrl => {
      if (webClientBaseUrl) {
        this.webClientBaseUrl = webClientBaseUrl;
      }
    });
  }

  getUserImageUrl(): SafeUrl {
    return this.userImageUrl;
  }

  getAssistantImageUrl(): SafeUrl {
    return this.assistantImageUrl;
  }

  getWebClientBaseUrl(): string {
    return this.webClientBaseUrl;
  }
}
