<abp-page [title]="'Saas::Tenants' | abpLocalization" [toolbar]="data.items">
  <div id="wrapper">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="Saas">
      <abp-advanced-entity-filters-form>
        <form #filterForm [formGroup]="formFilters">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label class="form-label" for="editionIdFilter">
                  {{ 'Saas::Edition' | abpLocalization }}
                </label>
                <abp-lookup-select
                  [emptyOption]="emptyOption"
                  cid="editionIdFilter"
                  [getFn]="getEditionLookup()"
                  formControlName="editionId"
                ></abp-lookup-select>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label
                  class="form-label"
                  [ngbTooltip]="'Saas::EditionEndDateToolTip' | abpLocalization"
                >
                  {{ 'Saas::DisplayName:ExpirationDate' | abpLocalization }}
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                </label>
                <abp-date-range-picker
                  formControlName="times"
                  startDateProp="fromDate"
                  endDateProp="toDate"
                >
                </abp-date-range-picker>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label for="activationState" class="form-label">{{
                  'Saas::DisplayName:ActivationState' | abpLocalization
                }}</label>
                <select
                  id="activationState"
                  class="form-select form-control"
                  formControlName="activationState"
                >
                  <option [ngValue]="null">-</option>
                  <option *ngFor="let data of activationStateOptions" [ngValue]="data.key">
                    {{ 'Saas::Enum:TenantActivationState.' + data.key | abpLocalization }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-auto align-self-end mb-3">
              <div class="row">
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                    <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                  </button>
                </div>
                <div class="col-6 col-sm-auto d-grid">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="formFilters.invalid"
                    (click)="getData()"
                  >
                    <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>

    <div class="card">
      <abp-extensible-table
        [data]="data.items"
        [recordsTotal]="data.totalCount"
        [list]="list"
      ></abp-extensible-table>
    </div>
  </div>
</abp-page>

<abp-modal [(visible)]="isModalVisible" [busy]="modalBusy" [options]="{ size: 'lg' }">
  <ng-template #abpHeader>
    <h3>{{ selectedModalContent.title | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <ng-container *ngTemplateOutlet="selectedModalContent?.template"></ng-container>
  </ng-template>

  <ng-template #abpFooter>
    <button abpClose type="button" class="btn btn-secondary">
      {{ 'Saas::Cancel' | abpLocalization }}
    </button>
    <ng-container [ngSwitch]="selectedModalContent?.type">
      <abp-button
        iconClass="fa fa-check"
        buttonType="submit"
        *ngSwitchCase="'saveTenant'"
        formName="editOrCreateForm"
        >{{ 'AbpIdentity::Save' | abpLocalization }}</abp-button
      >
      <abp-button
        iconClass="fa fa-check"
        *ngSwitchCase="'saveConnStr'"
        [disabled]="isDisabledSaveButton"
        (click)="save()"
        >{{ 'AbpIdentity::Save' | abpLocalization }}</abp-button
      >
    </ng-container>
  </ng-template>
</abp-modal>

<ng-container *ngIf="setTenantReplaceableTemplateOptions">
  <abp-set-tenant-password-modal
    *abpReplaceableTemplate="setTenantReplaceableTemplateOptions"
    [modalVisible]="isVisibleSetTenantPasswordModal"
    [tenantId]="selectedTenantId"
    [tenantName]="selectedTenantNameForSetTenantPasswordModal"
    (modalVisibleChange)="closeSetTenantPasswordModal()"
  >
  </abp-set-tenant-password-modal>
</ng-container>

<ng-template #tenantModalTemplate>
  <ng-container *ngTemplateOutlet="selected?.id ? tenantFormTmp : createTenantTmp"></ng-container>

  <ng-template #createTenantTmp>
    <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink>{{ 'Saas::TenantBaseInfo' | abpLocalization }}</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="tenantFormTmp"></ng-container>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>{{ 'Saas::ConnectionStrings' | abpLocalization }}</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="connectionStrings"></ng-container>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </ng-template>

  <hr class="solid">
  <br/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column gap-2">
      <p-button [disabled]="!tenantForm.pristine" (onClick)="testTenantLogin()">Test login</p-button>
      <p-button [disabled]="!tenantForm.pristine" (onClick)="testTenant2FALogin()">Test 2FA</p-button>
    </div>
    <pre class="w-75">{{ tenantTestLoginResult | json }}</pre>
  </div>

  <ng-template #tenantFormTmp>
    <form
      class="py-2"
      id="editOrCreateForm"
      *ngIf="tenantForm"
      [formGroup]="tenantForm"
      (ngSubmit)="save()"
      validateOnSubmit
    >
      <div class="mb-3">
        {{ statText }} Anfragen
      </div>
      <div class="mb-3">
        <label for="userImage" class="form-label">User Image (PNG only)</label>
        <input
          type="file"
          class="form-control"
          id="userImage"
          (change)="onUserImageFileSelected($event)"
        />
        <div *ngIf="userImageFileError" class="text-danger">
          {{ userImageFileError }}
        </div>
      </div>
      <div class="mb-3">
        <label for="assistantImage" class="form-label">Assistant Image (PNG only)</label>
        <input
          type="file"
          class="form-control"
          id="assistantImage"
          (change)="onAssistantImageFileSelected($event)"
        />
        <div *ngIf="assistantImageFileError" class="text-danger">
          {{ assistantImageFileError }}
        </div>
      </div>
      <abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>
    </form>
  </ng-template>

  <ng-template #connectionStrings>
    <abp-connection-strings
      *abpReplaceableTemplate="{
        outputs: { formSubmit: save },
        componentKey: connectionStringsReplacementKey
      }"
      (formSubmit)="save()"
    ></abp-connection-strings>
  </ng-template>
</ng-template>

<ng-template #connectionStringModalTemplate>
  <abp-connection-strings
    *abpReplaceableTemplate="{
      inputs: {
        selectedTenant: { value: selected }
      },
      outputs: { formSubmit: save },
      componentKey: connectionStringsReplacementKey
    }"
    [selectedTenant]="selected"
    (formSubmit)="save()"
  ></abp-connection-strings>
</ng-template>

<abp-feature-management
  *abpReplaceableTemplate="{
    inputs: {
      providerName: { value: 'T' },
      providerKey: { value: providerKey },
      visible: { value: visibleFeatures, twoWay: true }
    },
    outputs: { visibleChange: onVisibleFeaturesChange },
    componentKey: 'FeatureManagement.FeatureManagementComponent'
  }"
  [(visible)]="visibleFeatures"
  providerName="T"
  [providerKey]="providerKey"
>
</abp-feature-management>

<abp-impersonate-tenant-modal/>
