import {
  LPX_THEMES,
  LPX_THEME_STYLES_DEFAULTS,
  LpxTheme,
} from '@volosoft/ngx-lepton-x';

export const PmTheme = new LpxTheme({
  bundles: [
    {
      bundleName: "bootstrap-pm-theme", // it is optional. You can add one or more bundles.
    },
    {
      bundleName: "pm-theme",// bundle names will be used in angular.json
    },
  ],
  styleName: 'pm',
  label: 'PM',
  icon: 'bi bi-sun-fill',
});

export const PM_THEME_PROVIDER = [
  {
    provide: LPX_THEMES,
    useValue: [...LPX_THEME_STYLES_DEFAULTS, PmTheme],
  },
];
