
<div class="lpx-mobile-navbar-container">
  <div class="lpx-mobile-navbar">

    <!-- LINKS -->
    <div class="lpx-mobile-nav-tab-left">
      <ul class="lpx-custom-mobile-nav-tab-left">
        <div *ngIf="navTabs.length > 1"
             [class.selected]="activeMenu === navTabs[1].text"
             (click)="toggleMenu(navTabs[1].text, navTabs[1].children)">
          <a class="lpx-custom-mobile-nav-item"
             [routerLink]="navTabs[1].link"
             [routerLinkActive]="navTabs[1].link ? 'selected' : ''"
             [routerLinkActiveOptions]="{ exact: navTabs[1].link === '/' }">
            <span class="material-symbols-outlined">headset_mic</span>
            <span class="mobile-item-text"> Assistiert </span>
          </a>
        </div>
      </ul>
    </div>

    <!-- MITTE -->
    <div class="lpx-mobile-nav-tab-center">
      <ul class="lpx-custom-mobile-nav-tab-middle">
        <div *ngIf="navTabs.length > 0"
             [class.selected]="activeMenu === navTabs[0].text"
             (click)="toggleMenu(navTabs[0].text, navTabs[0].children)">
          <div class="lpx-custom-mobile-nav-item"
               [routerLink]="navTabs[0].link"
               [routerLinkActive]="navTabs[0].link ? 'selected' : ''"
               [routerLinkActiveOptions]="{ exact: navTabs[0].link === '/' }">
            <span class="material-symbols-outlined">search</span>
            <span class="mobile-item-text"> Frei </span>
          </div>
        </div>
      </ul>
    </div>

    <!-- RECHTS -->
    <div class="lpx-mobile-nav-tab-right">
      <div
        *ngIf="isAuthenticated()"
        class="lpx-custom-mobile-nav-tab-right"
        [class.selected]="activeMenu === 'profile'"
        (click)="toggleMenu('profile', profileMenuItems)"
      >
        <a class="lpx-custom-mobile-nav-item-user">
          <lpx-avatar [avatar]="avatar()"></lpx-avatar>
          <span class="mobile-item-text">{{ userName() }}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="lpx-mobile-menu" [hidden]="!menuVisible">
    <div class="lpx-logo-container">
      <ng-container
        *ngTemplateOutlet="logoPanel?.template || defaultLogo"
      ></ng-container>
    </div>
    <div
      class="lpx-context-menu--header"
      *ngIf="isAuthenticated() && activeMenu === 'profile'"
    >
      <div class="lpx-user-ctx-header">
        <div class="lpx-user-ctx-img">
          <lpx-avatar [avatar]="avatar()"></lpx-avatar>
        </div>
        <div class="lpx-user-ctx-info">
          <span class="lpx-context-menu-user-name">
            {{ userName() }}
          </span>
        </div>
      </div>
    </div>

    <lpx-navbar-routes
      [navbarItems]="selectedMenuItems"
      [groupedItems]="groupedItems$ | async"
      (routeClick)="closeMenu()"
    >
    </lpx-navbar-routes>

    <ng-container *ngIf="activeMenu === 'profile'">
      <hr class="my-2 mx-2 mr-2" />
      <lpx-mobile-toolbar
        [items]="toolbarService.items$ | async"
        (routeClick)="closeMenu()"
      >
      </lpx-mobile-toolbar>
    </ng-container>
  </div>
</div>

<ng-template #defaultLogo>
  <lpx-brand-logo></lpx-brand-logo>
</ng-template>
