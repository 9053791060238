<nav class="lpx-nav">
  <div class="lpx-logo-container">
    <ng-container
      *ngTemplateOutlet="defaultLogo"
    ></ng-container>
    <lpx-icon
      class="menu-collapse-icon hidden-in-hover-trigger"
      iconClass="pi pi-angle-left"
      (click)="toggleSidebarHover()"
    ></lpx-icon>
  </div>

  <div class="pm-expand-button-container">
    <lpx-icon
      class="menu-expand-icon"
      iconClass="pi pi-angle-right"
      (click)="toggleSidebarHover()"
    ></lpx-icon>
  </div>

  <ng-container
    *ngTemplateOutlet="defaultRouteTemplate; context: {
      $implicit: service.navbarItems$ | async,
      groupItems: service.groupedNavbarItems$ | async
    }">
  </ng-container>
</nav>

<ng-template #defaultRouteTemplate let-items let-groupItems="groupItems">
  <lpx-navbar-routes
    [navbarItems]="items"
    [groupedItems]="groupItems"
    [routerItem]="true">
  </lpx-navbar-routes>
</ng-template>

<ng-template #defaultLogo>
  <div class="pm-brand-logo">
    <div class="pm-brand-icon-container">
      <img class="pm-brand-icon" src="assets/images/logo/enaio.png" alt="logo">
    </div>
    <label class="pm-brand-name">enaio® gpt</label>
  </div>
</ng-template>
