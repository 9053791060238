import {Injectable, OnDestroy} from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import {interval, Subscription, switchMap} from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Document } from '../models/document.model';
import { EnaioSessionService } from './enaio-session.service';

export class FilterSettings {
  documentIds?: string[];
  registerInternalNames?: string[];
  registerIds?: string[];
  folderIds?: string[];
  cabinetInternalNames?: string[];

  constructor(documentIds?: string[], registerInternalNames?: string[], registerIds?: string[],
              folderIds?: string[], cabinetInternalNames?: string[]) {
    this.documentIds = documentIds;
    this.registerInternalNames = registerInternalNames;
    this.registerIds = registerIds;
    this.folderIds = folderIds;
    this.cabinetInternalNames = cabinetInternalNames;
  }
}

const REFRESH_INTERVAL: number = 120000;

@Injectable({
  providedIn: 'root'
})
export class HubService implements OnDestroy {
  private hubConnection: HubConnection;
  private connectionEstablished = false;
  private cabinetData: Record<string, string> = {};
  private tenantQuestionStats: Record<string, string> = {};
  private sessionRefreshSubscription : Subscription;

  constructor(private oAuthService: OAuthService, private enaioSessionService: EnaioSessionService) {
    const tenantName: string = this.getTenantName();
    if (tenantName) {
      this.createConnection(tenantName);
      this.startConnection().then(async () => {
        this.connectionEstablished = true;
        await this.addSessionRefreshTimer();
        await this.pullCabinetData();
        await this.getTenantQuestionStats(this.getTenantId());
      });
    }
  }

  ngOnDestroy(): void {
    if (this.sessionRefreshSubscription) {
      this.sessionRefreshSubscription.unsubscribe();
    }
    this.disconnect();
  }

  public async addReceiveChunksListener(callback: (chunks: Document[]) => void) {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.on('ReceiveChunks', (data: string) => {
        const chunks = JSON.parse(data);
        callback(chunks);
      });
    }
  }

  public async addReceiveMessageListener(callback: (message: string) => void) {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.on('ReceiveMessage', (message) => {
        callback(message);
      });
    }
  }

  public async addFinishedMessageListener(callback: () => void) {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.on('FinishedMessage', () => {
        callback();
      });
    }
  }

  public async addRagProgressChangedListener(callback: (progressPercentage: number, currentProcess: string) => void) {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.on('ReceiveRagProgress', (progressPercentage, currentProcess) => {
        callback(progressPercentage, currentProcess);
      });
    }
  }

  public async addStreamInterruptedListener(callback: () => void) {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.on('StreamInterrupted', () => {
        callback();
      });
    }
  }

  public async interruptStream() {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.send('InterruptStreamAsync')
        .then(() => console.debug('Stream interrupt sent'))
        .catch(err => console.debug(err));
    }
  }

  public async sendMessage(message: string, filterSettings: FilterSettings) {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.send('SendMessage', message, filterSettings)
        .catch(err => console.debug(err));
    }
  }

  public async sendSummarizeMessage() {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.hubConnection.send('SendSummarizeMessage')
        .catch(err => console.debug(err));
    }
  }

  public async getCabinetData() {
    await this.ensureCabinetDataPulled();
    return this.cabinetData;
  }

  private async pullCabinetData() {
    await this.ensureConnectionEstablished();

    if (this.hubConnection && this.connectionEstablished) {
      this.cabinetData = await this.hubConnection.invoke('PullCabinetData')
        .then((data: Record<string, string>) => {
          console.debug("Successfully pulled cabinet data");
          return data; // Return the transformed data
        })
        .catch(err => {
          console.error("Error fetching cabinet data", err);
          throw err;
        });
    }
  }

  public async getTenantQuestionStats(tenantId?: string) {
    await this.ensureConnectionEstablished();
    if (this.hubConnection && this.connectionEstablished) {
      this.tenantQuestionStats = await this.hubConnection.invoke('PullTenantQuestionStats', tenantId)
        .then((data: Record<string, string>) => {
          return data; // Return the transformed data
        })
        .catch(err => {
          console.error("Error fetching user statistics", err);
          throw err;
        });
      return (this.tenantQuestionStats["totalQuestions"] ?? "0") + " / " + (this.tenantQuestionStats["maxQuestions"] ?? "0");
    }
  }

  private addSessionRefreshTimer = async (): Promise<void> => {
    // Create an observable that emits a value every 120 seconds (2 minutes)
    const source = interval(REFRESH_INTERVAL);

    // Subscribe to the observable to execute your function
    this.sessionRefreshSubscription = source.pipe(
      switchMap(() => this.refreshSession())
    ).subscribe();
  }

  private async refreshSession(): Promise<void> {
    const isSessionValid = await this.enaioSessionService.refreshSession();
    if (!isSessionValid) {
      this.oAuthService.revokeTokenAndLogout()
        .then(() => console.debug('Session invalid. Logging out user.'))
        .catch(err => console.debug('Error while logging out user: ' + err));
    }
  }

  private createConnection(tenantName: string): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.apis.default.url.replace("{0}", tenantName ) + '/hubroute', { accessTokenFactory: () => localStorage.getItem('access_token') })
      .withAutomaticReconnect()
      .build();
  }

  private startConnection = async () => {
    try {
      await this.hubConnection.start();
    } catch (err) {
      console.debug('Error while starting Hub connection: ' + err);
    }
  }

  private disconnect(): void {
    this.hubConnection.stop().then(() => console.debug('Connection stopped'));
  }

  private async ensureConnectionEstablished() {
    // Retry connection every 500ms if not connected, up to a certain timeout
    const maxRetries = 60;
    let currentRetry = 0;

    while (!this.connectionEstablished && currentRetry < maxRetries) {
      await new Promise(resolve => setTimeout(resolve, 500));
      currentRetry++;
    }

    if (!this.connectionEstablished) {
      throw new Error('Unable to establish connection.');
    }
  }

  private async ensureCabinetDataPulled() {
    // Retry connection every 500ms if not connected, up to a certain timeout
    const maxRetries = 60;
    let currentRetry = 0;

    while (Object.keys(this.cabinetData).length === 0 && currentRetry < maxRetries) {
      await new Promise(resolve => setTimeout(resolve, 500));
      currentRetry++;
    }

    if (Object.keys(this.cabinetData).length === 0) {
      throw new Error('Unable to get cabinet data.');
    }
  }

  private getTenantName(): string {
    const abpSessionString: string= localStorage.getItem("abpSession");
    let tenantName: string = "";
    if (abpSessionString) {
      const abpSession = JSON.parse(abpSessionString);
      tenantName = abpSession.tenant?.name;
      return tenantName;
    }
    return null;
  }

  private getTenantId(): string {
    const abpSessionString: string= localStorage.getItem("abpSession");
    if (abpSessionString) {
      const abpSession = JSON.parse(abpSessionString);
      return abpSession.tenant?.id;
    }
    return null;
  }
}
