import { Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NavbarService, LayoutService, LpxNavbarModule, LpxIconModule } from '@volo/ngx-lepton-x.core';
import { AsyncPipe, CommonModule, isPlatformBrowser, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'pm-navbar',
  templateUrl: './pm-navbar.component.html',
  styleUrl: './pm-navbar.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    LpxNavbarModule,
    LpxIconModule,
    NgTemplateOutlet,
    AsyncPipe,
    CommonModule
  ]
})
export class PmNavbarComponent {
  private didResized = false;

  constructor(
    public service: NavbarService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  toggleSidebarHover() {
    this.didResized = true;
    this.layoutService.toggleClass('hover-trigger');
  }
  ngAfterViewChecked() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.didResized) {
      this.didResized = false;
      window.dispatchEvent(new Event('resize'));
    }
  }
}
