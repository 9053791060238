import { Component } from '@angular/core';
import { AuthService, ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
import { ThemeService } from '@volosoft/ngx-lepton-x';
import { PmTheme } from './theme/theme.provider';
import { PmSideMenuLayoutComponent } from './components/pm-side-menu-layout/pm-side-menu-layout.component';
import { MobileNavbarComponent } from './components/pm-mobile-navbar';
import { ToolbarContainerComponent } from './components/pm-toolbar-container';
import { eSaasComponents } from '@volo/abp.ng.saas/lib/enums/components';
import { TenantCustomPropertiesService } from './services/tenant-custom-properties.service';
import { CustomTenantComponent } from './components/tenant/custom-tenant.component';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
  `,
})
export class AppComponent {
  constructor(
    private themeService: ThemeService,
    private replaceableComponents: ReplaceableComponentsService,
    private tenantCustomPropertiesService: TenantCustomPropertiesService,
    private oAuthService: OAuthService,
  ) {
    this.themeService.styles = this.themeService.styles.filter(obj => obj.styleName === 'pm');
    this.themeService.initTheme();
    this.themeService.setTheme(PmTheme);

    const sessionStr = localStorage.getItem('abpSession');
    if (sessionStr) {
        const session = JSON.parse(sessionStr);
        if (session?.tenant?.id) {
          this.tenantCustomPropertiesService.initProperties(session.tenant.id);
        }
    }

    this.replaceableComponents.add({
      component: PmSideMenuLayoutComponent,
      key: eThemeLeptonXComponents.ApplicationLayout
    })
    this.replaceableComponents.add({
      component: MobileNavbarComponent,
      key: eThemeLeptonXComponents.NavbarMobile
    })
    this.replaceableComponents.add({
      component: ToolbarContainerComponent,
      key: eThemeLeptonXComponents.Toolbar
    })
    this.replaceableComponents.add({
      component: CustomTenantComponent,
      key: eSaasComponents.Tenants
    })
  }
}
