import {
  Component,
  ContentChild,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subscription, EMPTY, map } from 'rxjs';
import {
  ICON_MAP,
  LogoPanelDirective,
  LpxNavbarItem,
  NavbarService,
  ToolbarService,
  UserProfile,
  UserProfileService,
} from '@volo/ngx-lepton-x.core';
import { MobileNavbarService } from './mobile-navbar.service';
import { MobileMenuTranslate } from './enums/mobile-menu-translate.enum';

@Component({
  selector: 'app-pm-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [MobileNavbarService],
})
export class MobileNavbarComponent implements OnInit, OnDestroy {
  protected readonly injector = inject(Injector);
  protected readonly service = inject(MobileNavbarService);
  protected readonly toolbarService = inject(ToolbarService);
  protected readonly navbarService = inject(NavbarService);
  protected readonly profileService = inject(UserProfileService);

  @ContentChild(LogoPanelDirective) logoPanel?: LogoPanelDirective;

  protected readonly currentUser = toSignal(this.profileService.user$, {
    initialValue: {} as UserProfile,
  });

  isAuthenticated = computed(() => this.currentUser().isAuthenticated);
  avatar = computed(() => this.currentUser().avatar);
  fullName = computed(() => this.currentUser().fullName);
  userName = computed(() => this.currentUser().userName);
  email = computed(() => this.currentUser().email);

  menuVisible = false;

  navTabs!: LpxNavbarItem[];
  selectedMenuItems!: LpxNavbarItem[];
  groupedItems$ = this.getGroupedItems();
  navbarMenuItems!: LpxNavbarItem[];
  settingsMenuItems!: LpxNavbarItem[];
  profileMenuItems?: LpxNavbarItem[];

  activeMenu = '';
  icons = ICON_MAP;
  sub = new Subscription();
  settingsTitle = MobileMenuTranslate.SettingsTitle;
  toggleClass = '';

  protected getGroupedItems() {
    return this.navbarService.groupedNavbarItems$.pipe(
      map((groupItems) =>
        groupItems?.map(({ group, items }) => ({
          group,
          items: items.filter((item) => !item.showOnMobileNavbar),
        }))
      )
    );
  }

  protected setNavTabs = (items: LpxNavbarItem[]) => {
    this.navTabs = items.filter((item) => item.showOnMobileNavbar);
    this.setNavbarMenuItems(items);
  };

  protected setNavbarMenuItems = (items: LpxNavbarItem[]) => {
    this.navbarMenuItems = items?.filter(
      (item) => !this.navTabs.includes(item)
    );
  };
  
  protected setSettingsMenuItems = (items: LpxNavbarItem[]) => {
    this.settingsMenuItems = items;
  };

  protected setProfileActions = (items?: LpxNavbarItem[]) => {
    // to add all options --> this.profileMenuItems = items;
    if (items && items.length > 0) {
      this.profileMenuItems = [items[items.length - 1]];
    } else {
      this.profileMenuItems = null;
    }
  };

  ngOnInit(): void {
    this.sub.add(this.navbarService.navbarItems$.subscribe(this.setNavTabs));
    this.sub.add(this.service.settings$.subscribe(this.setSettingsMenuItems));
    this.sub.add(
      this.service.userProfileActions$.subscribe(this.setProfileActions)
    );
  }

  onSubnavbarExpand(menuItem: LpxNavbarItem): void {
    if (menuItem.expanded) {
      this.navbarMenuItems
        ?.filter((item) => item !== menuItem)
        .forEach((item) => (item.expanded = false));
    }
  }

  toggleMenu(type?: string, menuItems?: LpxNavbarItem[]) {
    if (!type || !menuItems || !menuItems.length) {
      this.closeMenu();
      return;
    }

    this.toggleClass = '';
    if (type === this.activeMenu) {
      if (this.menuVisible) {
        this.closeMenu();
      }
    } else {
      this.menuVisible = true;
      this.activeMenu = type;
      this.groupedItems$ = type === 'navbar' ? this.getGroupedItems() : EMPTY;
      this.selectedMenuItems = menuItems;
    }
  }

  toggleNavbarMenu() {
    this.toggleMenu('navbar', this.navbarMenuItems);
    this.toggleClass = this.menuVisible ? 'lpx-mobile-menu-toggle-open' : '';
  }

  closeMenu() {
    this.toggleClass = '';
    this.activeMenu = '';
    this.menuVisible = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
